import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styles from '../styles/recipes.module.scss';

const Recipe = ({ title, slug, image }) => {
  return <div className={styles.recipesListItem}>
    <Link to={`/recepten/${slug}`}>
      <div className={styles.recipesListItemImage}>
        {!!image && image}
      </div>
      <div className={styles.recipesListItemTitle}>
        <h3>{title}</h3>
      </div>
    </Link>
  </div>
}

Recipe.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  image: PropTypes.node,
}

export default Recipe
