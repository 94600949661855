import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Store from './store';

import styles from '../styles/stores.module.scss';

const Stores = ({ id, data: { allContentfulStore: { edges }} }) => {
  const stores = edges.map(edge => <Store
    key={edge.node.id}
    title={edge.node.title}
    body={edge.node.body.childMarkdownRemark.html}
  />)

  return <div className={styles.stores} id={id}>
      <div className={styles.storesHeader}>
        <h2>Waar kan ik het kopen?</h2>
      </div>
      <div className={styles.storesList}>
        {stores}
      </div>
    </div>
};

Stores.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    allContentfulStore: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export default (props) => (
  <StaticQuery
    query={storesQuery}
    render={data => <Stores data={data} {...props} />}
  />
)

const storesQuery = graphql`
  query Stores {
    allContentfulStore {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
