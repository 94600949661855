import React from 'react';
import PropTypes from 'prop-types';

import styles from '../styles/stores.module.scss';

const Store = ({ title, body }) => (
  <div className={styles.storesListItem}>
    <div className={styles.storesListItemHeader}>
      <h3>{title}</h3>
    </div>
    <div
      style={{whiteSpace: 'pre-line'}}
      dangerouslySetInnerHTML={{
        __html: body,
      }}
    />
  </div>
)

Store.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

export default Store
