import React from 'react';

import hatSvg from '../images/hat.svg';

import styles from '../styles/hat.scss';

const Hat = () => (
  <div className='hat'>
    <img src={hatSvg} />
  </div>
)

Hat.propTypes = {

}

export default Hat
