import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Recipe from './recipe';

import styles from '../styles/recipes.module.scss';

const Recipes = ({ id, data: { allContentfulRecipe: { edges }} }) => {
  const recipes = edges.map(edge => <Recipe
    key={edge.node.id}
    title={edge.node.title}
    slug={edge.node.slug}
    image={!!edge.node.image && (<Img {...edge.node.image} />)}
  />)

  return <div className={styles.recipes} id={id}>
      <div className={styles.recipesHeader}>
        <h2>Recepten met Sambal</h2>
      </div>
      <div className={styles.recipesList}>
        {recipes}
      </div>
    </div>
};

Recipes.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    allContentfulRecipe: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export default (props) => (
  <StaticQuery
    query={recipesQuery}
    render={data => <Recipes data={data} {...props} />}
  />
)

const recipesQuery = graphql`
  query Recipes {
    allContentfulRecipe {
      edges {
        node {
          id
          title
          slug
          image {
            id
            title
            fluid(maxWidth: 640, toFormat: JPG, quality: 75) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`
